<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Settlement Cycles</h1>
      <div class="flex bg-gray-100 justify-between p-5 mt-5">
        <!-- <div class="flex items-center"> -->
        <div class="block w-full mx-6">
          <label>Settlement Cycle</label>
          <select
            v-model="settlementCycle"
            class="block px-2 ring-2 outline-none ring-blue-500 rounded-sm w-full text-sm py-2"
          >
            <option value="">Search</option>
          </select>
        </div>
        <div class="block w-full mx-6">
          <label>Date Range (From)</label>
          <input
            type="date"
            class="block px-2 ring-2 outline-none ring-blue-500 rounded-sm w-full text-sm py-2"
            name=""
            placeholder="Search by template name"
            id=""
            v-model="dateData.startDate"
          />
        </div>
        <div class="block w-full mx-6">
          <label>To</label>
          <input
            type="date"
            class="block px-2 ring-2 outline-none ring-blue-500 rounded-sm w-full text-sm py-2"
            name=""
            placeholder="Search by template name"
            id=""
            v-model="dateData.endDate"
          />
        </div>
        <div class="block">
          <button
            class="bg-blue-800 rounded-md px-10 py-2 mt-5 text-white"
            @click="handleFilter"
          >
            Filter
          </button>
        </div>
        <!-- </div> -->
      </div>

      <div
        class="mt-10 pb-5 w-full mx-auto cursor-pointer"
        style="height: 400px; overflow: auto"
      >
        <div
          class="w-8/12 mb-5 border-2 py-5 px-3 mx-auto border-gray-200 flex flex-col bg-gray-100 shadow-sm rounded-lg overflow-hidden"
          v-for="(settlement, i) in filterSettlements"
          :key="i"
          @click="
            $router.push({
              name: 'ViewSettlements',
              params: {
                id: settlement.settlement_code,
              },
            })
          "
        >
          <div class="px-6 py-4">
            <div class="flex items-center justify-between pt-3">
              <div class="ml-4">
                <p class="text-xs text-gray-700 mt-1">Cycle Reference</p>
                <p class="text-gray-700 text-xl">
                  {{ settlement.settlement_code }}
                </p>
              </div>
              <div class="ml-4">
                <p class="text-sm text-gray-700 mt-1">Time of Settlement</p>
                <p class="text-xl">
                  {{ formatDate(settlement.settlement_code) }}
                </p>
                <p class="text-xs text-gray">
                  {{ settlement.transaction_count }} Transactions
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "./general-components/Loading.vue";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      date: null,
      currentPage: 1,
      page_size: 10,
      status: "INIT",
      settlementCycle: "",
      month: {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      },
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["GET_ALL_SETTLEMENTS"]),
    filterSettlements() {
      return this.GET_ALL_SETTLEMENTS.data.settlements;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate() - 7)).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      creationDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    await this.fetchRecords();
  },
  methods: {
    async fetchRecords() {
      //FETCH_SETTLEMENTS
      this.isLoading = true;
      let payload = {
        page: this.currentPage,
        startDate: this.dateData.startDate,
        endDate: this.dateData.endDate,
        page_size: this.page_size,
        status: this.status,
      };
      try {
        let response = await this.$store.dispatch("FETCH_SETTLEMENTS", payload);
        this.isLoading = false;
        if (response.status) {
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleFilter() {
      this.isLoading = true;
      let payload = {
        page: this.currentPage,
        startDate: this.dateData.startDate,
        endDate: this.dateData.endDate,
        page_size: this.page_size,
        status: this.status,
      };
      try {
        let response = await this.$store.dispatch("FETCH_SETTLEMENTS", payload);
        this.isLoading = false;
        if (response.status) {
        }
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      return (
        "00:00 AM " +
        this.month[date[4] + date[5]] +
        " " +
        date[6] +
        date[7] +
        ", " +
        date[0] +
        date[1] +
        date[2] +
        date[3]
      );
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
